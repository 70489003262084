
  import { Pricing } from "../../components";
  
  const Course = () => {
    return (
      <>
        <Pricing/>
      </>
    );
  };
  
  export default Course;
  