import React from "react";
import { Button } from "../../globalStyles";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock, } from "react-icons/gi";
import { FaWindows, FaPaintBrush } from "react-icons/fa"
import { AiFillStar } from "react-icons/ai"
import { GiSpiderWeb } from "react-icons/gi"


import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from "./Pricing.elements";

const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Courses</PricingHeading>
          <PricingContainer>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaWindows />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in Advanced Computer Application</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>MS Office(Word,Excel, Advanced Excel,Power Point,Access)</PricingCardFeature>
                  <PricingCardFeature>Internet & E-mail</PricingCardFeature>
                  <PricingCardFeature>HTML & VBA</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaWindows />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in Computer Application</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>MS Office(Word,Excel, Advanced Excel,Power Point)</PricingCardFeature>
                  <PricingCardFeature>Internet</PricingCardFeature>
                  <PricingCardFeature>E-mail</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaWindows />
                </PricingCardIcon>
                <PricingCardPlan>Diploma Information Technology</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>MS Office(Word, Advanced Excel, Power Point, Access, Excel)</PricingCardFeature>
                  <PricingCardFeature>Internet</PricingCardFeature>
                  <PricingCardFeature>E-mail</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
          <PricingContainer>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaWindows />
                </PricingCardIcon>
                <PricingCardPlan>Basic Computer For Beginners</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>Introduction</PricingCardFeature>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>MS Word</PricingCardFeature>
                  <PricingCardFeature>Internet</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <FaPaintBrush />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in Desktop Publishing</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>Adobe</PricingCardFeature>
                  <PricingCardFeature>Photoshop</PricingCardFeature>
                  <PricingCardFeature>Coral Draw</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiSpiderWeb />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in Web Page Designing</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>MS Windows</PricingCardFeature>
                  <PricingCardFeature>HTML</PricingCardFeature>
                  <PricingCardFeature>DHTML</PricingCardFeature>
                  <PricingCardFeature>CSS & JS</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>

          </PricingContainer>
          <PricingContainer>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <AiFillStar />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in Software Programming</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>JAVA</PricingCardFeature>
                  <PricingCardFeature>C</PricingCardFeature>
                  <PricingCardFeature>C++</PricingCardFeature>
                  <PricingCardFeature>Python</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/contact">
              <PricingCardInfo>
                <PricingCardIcon>
                  <AiFillStar />
                </PricingCardIcon>
                <PricingCardPlan>Diploma in J2EE Programming</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>JDBC & RMI</PricingCardFeature>
                  <PricingCardFeature>Networking</PricingCardFeature>
                  <PricingCardFeature>Servlets</PricingCardFeature>
                  <PricingCardFeature>JSP,EJB,XML</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>

          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
};
export default Pricing;
