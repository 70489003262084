import { send } from 'emailjs-com';
import {
  FooterContainer,
  FooterSubHeading,
  FooterSubscription,
  Form,
  FormInput,
  FormInputComment
} from "./FormFill.elements";
import { Button } from "../../globalStyles";
import { useState } from "react";
import styled from 'styled-components';
import { Alert } from "@mui/material"

const ButtonStyle = styled(Button)`
  margin-top: 15px;
  width: 50%;
`;

const FormFill = () => {
  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    message: '',
    reply_to: '',
    email: '',
    p_number: ''
  });
  const [status, setStatus] = useState(undefined);
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_qwpp7ap',
      'template_ae69pud',
      toSend,
      'Jzv-5Oyw_-VEYJ8rr'
    )
      .then(() => {
        setStatus({ type: 'success' });
      })
      .catch((error) => {
        setStatus({ type: 'error', error });
      });
  };
  return (
    <FooterContainer>
      <FooterSubscription>
        {status?.type === 'success' && <Alert severity="success">Registered Successfully!</Alert>}
        {status?.type === 'error' && (
          <Alert severity="error">Unsuccessfull</Alert>
        )}
        <FooterSubHeading>
          Send us your details here!
        </FooterSubHeading>
        <Form onSubmit={onSubmit}>
          <FormInput name="email" type="email" placeholder="Your Email" value={toSend.email}
            onChange={handleChange} />
          <FormInput name="from_name" type="text" placeholder="Your Name" value={toSend.from_name}
            onChange={handleChange} />
          <FormInput name="p_number" type="text" placeholder="Your Phone number" value={toSend.p_number}
            onChange={handleChange} />
          <FormInputComment name="message" type="text" placeholder="Your Comments" value={toSend.message}
            onChange={handleChange} />
          <ButtonStyle type="submit" fontBig>Submit</ButtonStyle>
        </Form>
      </FooterSubscription>
    </FooterContainer>
  );
};

export default FormFill;