const homeObjectOne = {
  alt: "Image",
  buttonLabel: "Get Started",
  description:
    "We help students get the best computer knowledge catering to their needs and visions.",
  headline: "One stop destination for every student out there",
  img: require("../../images/svg-1.svg").default,
  imgStart: "",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  lightTopLine: true,
  primary: true,
  start: "",
  topLine: "Computer Institue",
};

const homeObjectTwo = {
  alt: "Image",
  buttonLabel: "Get Started",
  description:
    "Computer Point's mission is to create a pool of high quality software professionals who will meet the demanding needs of multinational organizations for tomorrow's challenges. Training methods have been maximized with learning rather than teaching through well equipped lab ONE-TO-ONE ratio.",
  headline: "One Computer for one Student",
  img: require("../../images/techSupport.png").default,
  imgStart: "start",
  lightBg: true,
  lightText: false ,
  lightTextDescription: false,
  lightTopLine: false,
  primary: false,
  start: "true",
  topLine: "About us",
};

const homeObjectThree = {
  alt: "Image",
  buttonLabel: "Get Started",
  description:
    "We believe that training in the latest computer technologies combined with life-long learning are the essential ingredients for building a successful career in the IT Industry. Students are trained in a multi-cultural atmosphere providing student-centered interactive learning which is centered on today's computerized business environment. ",
  headline: "Practice makes a man perfect",
  img: require("../../images/svg-2.svg").default,
  imgStart: "",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  lightTopLine: true,
  primary: true,
  start: "",
  topLine: "Our Philosophy",
};

export { homeObjectOne, homeObjectTwo, homeObjectThree };
