import { useState, useEffect } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Clogo from "./../../images/clogoHighRez.png";
import styled from "styled-components";

import {
  MobileIcon,
  Nav,
  NavButtonLink,
  NavbarContainer,
  NavItem,
  NavItemButton,
  NavLinks,
  NavLogo,
  NavMenu,
} from "./Navbar.elements";
import { Button } from "../../globalStyles";

const ImageLogo = styled.img`
  margin-right: 1%;
`;

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  useEffect(() => {
    showButton();
  }, []);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => setButton(window.innerWidth <= 960);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              <ImageLogo width="50px" src={Clogo} alt="computer-logo" />
              {`COMPUTER POINT`}
            </NavLogo>

            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/">Home</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to="/course">Course</NavLinks>
              </NavItem>

              <NavItemButton>
                {button ? (
                  <NavButtonLink to="/contact">
                    <Button primary>CONTACT US</Button>
                  </NavButtonLink>
                ) : (
                  <NavButtonLink to="/contact">
                    <Button
                      fontBig
                      primary
                    >
                      CONTACT US
                    </Button>
                  </NavButtonLink>
                )}
              </NavItemButton>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
