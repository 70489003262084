import  FormFill  from "../../components/Form/FormFill";
  
  const Contact = () => {
    return (
      <>
        <FormFill/>
      </>
    );
  };
  
  export default Contact;
  