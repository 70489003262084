import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { send } from 'emailjs-com';
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterSubHeading,
  FooterSubText,
  FooterSubscription,
  Form,
  FormInput,
  SocialIcon,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./Footer.elements";
import { Button } from "../../globalStyles";
import { useState } from "react";
import Clogo from "./../../images/clogoHighRez.png";
import styled from "styled-components";

const ImageLogo = styled.img`
  margin-right: 1%;
`;

const Footer = () => {
  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    message: '',
    reply_to: '',
  });
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_qwpp7ap',
      'template_ae69pud',
      toSend,
      'Jzv-5Oyw_-VEYJ8rr'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            <ImageLogo width="50px" src={Clogo} alt="computer-logo" />
            COMPUTER POINT
          </SocialLogo>
          <WebsiteRights>Computer Point © 2022</WebsiteRights>
          <SocialIcons>
            <SocialIconLink href="https://www.facebook.com/Computer-Point-105379245443993/?ref=page_internal" target="_blank" aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="https://www.facebook.com/Computer-Point-105379245443993/?ref=page_internal" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href="https://www.facebook.com/Computer-Point-105379245443993/?ref=page_internal" target="_blank" aria-label="Linkedin">
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;